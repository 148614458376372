
<template>
  <div>
    <button @click="handleBranchChangeClick" class="c-header-nav-btn mx-0 px-0" v-c-tooltip="'Change Home Branch'">
      <CIcon name="cil-transfer" size="sm" />
    </button>

    <CModal :centered="false"  title="Choose A Branch" :show.sync="showSelectBranch">
      <CBranchSelect style="z-index: 999; min-width: 200px" :branches="branchList" @change="handleBranchChange">
      </CBranchSelect>
      <template #footer>
        <CButton color="" @click="showSelectBranch = false">Cancel
        </CButton>
        <CButton color="info" @click="onOKClick">OK </CButton>
      </template>
    </CModal>
  </div>

</template>


<script>
/** Custom Component that allows easy branch selection via pop-up control   
 * 
*/

import CBranchSelect from "@/components/iotcore/CBranchSelect";
export default {
  name: "BranchSelectorComponent",
  components: {
    CBranchSelect
  },
  props: {

  },
  data() {
    return {
      showSelectBranch: false,
      selectedBranch: undefined,
    }
  },
  computed: {
    branchList() {
      if (this.$store.getters.branches)
        return this.$store.getters.branches.map(item => {
          return { ...item, img: this.getBranchImg(item) };
        });
      else
        return undefined; 
    },
    branch() {
      let branch_id = this.$auth.branch_id; // get your home branch 
      // Note: you can have a different selected branch (Admin Users )
      return this.$store.getters.selectedBranch || branch_id;
    },
  },
  methods: {
    handleBranchChangeClick(e) {
      this.showSelectBranch = !this.showSelectBranch;
    },
    handleBranchChange(item, index) {
      this.selectedBranch = item;
      this.$emit("selected", item); 

    },
    onOKClick(e) {
      this.showSelectBranch = false;
      this.onBranchSelect(e);
    },
    onBranchSelect(e) {
      let old = this.branch.branch_id; // Get the current  Branch ID 
      let item = this.selectedBranch; // Get the Selected from the Control 
      let newBranch_id = item.branch_id;  // What is the new Selected branch_id
      this.$store.commit("selectBranch", newBranch_id);
      if(old!=newBranch_id) // Did it change? 
        this.$emit("change", this.item, item.branch_id); 
    },
    getBranchImg(branchInfo) {
      let id;
      try {
        id = branchInfo ? branchInfo.metadata.code : "";
        id = id.toLowerCase();
        if (!id) {
          return "";
        }
      } catch (err) {
        console.log(err);
        return "";
      }
      return `https://i2.wp.com/cdn.auth0.com/avatars/${id}.png?ssl=1`;
    }
  }
}
</script>

