<template>
  <div>
    <multiselect
      class="style-chooser rounded border border-info"
      v-model="selectedBranch"
      :options="options"
      :searchable="true"
      :close-on-select="true"
      :show-no-results="true"
      :show-labels="true"
      :taggable="false"
      select-label="Select Branch "
      deselect-label="Unselect Branch"
      
      label="label"
      :allow-empty="false"
      :preselect-first="preselectFirst"
      track-by="label"
      :multiple="false"
      :value="selectedBranch"
      @input="inputChanged"
      @search-change="inputChanged"
      :disabled="disabled"
      placeholder="Select Branch"
    >
      <template slot="option" slot-scope="props">
          <div v-if="!props.option.$isLabel">
         <span class="option__title ml-2 pl-2 mr-2 float-left">
           <c-avatar :initials="props.option.code" :username="props.option.name"/> 
           <!--img :src="props.option.img" height="40"/--> </span>
        <span  class="option__title">
          <div><span ><strong>{{ props.option.label }}</strong> </span> </div>
          <div class="font-weight-light"><small class="font-weight-light" >UID: {{ props.option.uid }} </small> </div>
        </span>
        </div>
        <span v-else class="option__title pl-2">
          <strong>
            {{ props.option.label }}
          </strong>
        </span>
      </template>
    </multiselect>
  </div>
</template>


<script>
/** Creates a Single Select Component using Vue MultiSelect for Choosing Users
 *
 * Note: this version does not support Multi-Select and is Not taggable
 * Note: A different Version could be built to support those features down the road
 */
import Multiselect from "vue-multiselect";
import "vue-multiselect/dist/vue-multiselect.min.css";
import CAvatar from "../base/CAvatar"; 


//const DEFAULT_IMG = `https://i2.wp.com/cdn.auth0.com/avatars/ar.png?ssl=1`
const DEFAULT_IMG = `https://ui-avatars.com/api/?name=LCE&length=3&background=random`

export default {
  name: "CBranchSelect",
  components:{
    CAvatar
  },
  props: {
    title: String,
    branches: {
      type: Array
    },
    preselectFirst:{
      type: Boolean, 
      default: true,
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  components: {
    Multiselect,
    CAvatar
  },
  data() {
    return {
      //selectedUser: undefined,
      selectedOption: undefined, 
      edit: true
    };
  },

  computed: {
    selectedBranch :{
        get(){
            return this.selectedOption
        }, 
        set(val){
            this.selectedOption = val; 
        }
    },
    branch() {
      return this.selectedBranch;
    },
    options() {
      /*
        {
          key: "0",
          label: "Viewer Only",
          value: "viewer",
          disabled: !this.edit
        },
        */
       if(!this.branches){
         return []
       }
      let tempList = this.branches.map(item => {
        return {
          key: item.id,
          name: item.name, 
          code: item.metadata.code, 
          label: this.getBranchName(item),
          value: item,
          img: this.getBranchImg(item), 
          uid: item.uid, 
        };
      });
      //{ key: "1", label: "Operator", value: "viewer", disabled: !this.edit },
      //{ key: "2", label: "Admin", value: "admin", disabled: !this.edit }
      return tempList;
    },


    userImage() {
      if (this.user) return this.branch.img;
      /*
      else if (this.user.picture) return this.user.picture;
      */ else
        return defaultIMG;
    },
    
    fields() {
      return [
        { key: "key", label: this.username, _style: "width:150px" },
        { key: "value", label: "", _style: "width:150px;" }
      ];
    },
   
  },

  methods: {
    inputChanged(arg, id) {
      // Do nothing
      // Emit Selection Changed
      let branch = arg.value; 
      let index = arg.key -1; 
      //console.log(user, index);
      this.$emit("change", branch, index); 
    },
    searchChanged(arg, id) {
      // Don't do anything
      console.log(arg, id);
    },
    getBranchImg(branch) {
      return branch&& branch.img ? branch.img : DEFAULT_IMG ;
    },
    getBranchName(item) {
      return `${item.name}`;
    },

    getDateTime(date) {
      return this.$moment(date).format("MMMM Do YYYY, h:mm:ss a");
    },
    

    getBadgeColor(item) {
      if (!item || !item.status) {
        return "danger";
      }
      switch (item.status.toLowerCase()) {
        case "active":
          return "success";
          break;
        case "inactive":
          return "warning";
          break;
        default:
          return "danger";
      }
    },

    initForm(data) {},
    load() {}
  },
  activated() {
    this.load();
  },
  mounted() {
    this.load();
  }
};
</script>


