var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "CDropdown",
    {
      staticClass: "c-header-nav-items",
      attrs: { inNav: "", placement: "bottom-end", "add-menu-classes": "pt-0" },
      scopedSlots: _vm._u([
        {
          key: "toggler",
          fn: function() {
            return [
              _c("CHeaderNavLink", [
                _c("div", { staticClass: "c-avatar" }, [
                  _c("img", {
                    staticClass: "c-avatar-img ",
                    attrs: { src: _vm.avatar }
                  })
                ])
              ])
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _c(
        "CDropdownHeader",
        { staticClass: "text-center", attrs: { tag: "div", color: "light" } },
        [_c("strong", [_vm._v("User ")])]
      ),
      _c(
        "CDropdownItem",
        { attrs: { to: "/profile" } },
        [_c("CIcon", { attrs: { name: "cil-user" } }), _vm._v(" Profile ")],
        1
      ),
      _c(
        "CDropdownItem",
        [
          _c("CHeaderNavItem", { staticClass: "mx-0 px-0" }, [
            _c(
              "span",
              { staticClass: " mt-0 pt-0" },
              [
                _c("CIcon", { attrs: { name: "cil-home" } }),
                _vm._v(" Branch: " + _vm._s(_vm.branchName) + " ")
              ],
              1
            )
          ])
        ],
        1
      ),
      false
        ? _c(
            "CDropdownItem",
            [
              _c("CIcon", { attrs: { name: "cil-settings" } }),
              _vm._v(" Settings ")
            ],
            1
          )
        : _vm._e(),
      _c("CDropdownDivider"),
      false
        ? _c(
            "CDropdownItem",
            [
              _c("CIcon", { attrs: { name: "cil-shield-alt" } }),
              _vm._v(" Lock Account ")
            ],
            1
          )
        : _vm._e(),
      _c(
        "CDropdownItem",
        {
          on: {
            click: function($event) {
              return _vm.logout()
            }
          }
        },
        [
          _c("CIcon", { attrs: { name: "cil-lock-locked" } }),
          _vm._v(" Logout ")
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }