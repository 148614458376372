var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "button",
        {
          directives: [
            {
              name: "c-tooltip",
              rawName: "v-c-tooltip",
              value: "Change Home Branch",
              expression: "'Change Home Branch'"
            }
          ],
          staticClass: "c-header-nav-btn mx-0 px-0",
          on: { click: _vm.handleBranchChangeClick }
        },
        [_c("CIcon", { attrs: { name: "cil-transfer", size: "sm" } })],
        1
      ),
      _c(
        "CModal",
        {
          attrs: {
            centered: false,
            title: "Choose A Branch",
            show: _vm.showSelectBranch
          },
          on: {
            "update:show": function($event) {
              _vm.showSelectBranch = $event
            }
          },
          scopedSlots: _vm._u([
            {
              key: "footer",
              fn: function() {
                return [
                  _c(
                    "CButton",
                    {
                      attrs: { color: "" },
                      on: {
                        click: function($event) {
                          _vm.showSelectBranch = false
                        }
                      }
                    },
                    [_vm._v("Cancel ")]
                  ),
                  _c(
                    "CButton",
                    { attrs: { color: "info" }, on: { click: _vm.onOKClick } },
                    [_vm._v("OK ")]
                  )
                ]
              },
              proxy: true
            }
          ])
        },
        [
          _c("CBranchSelect", {
            staticStyle: { "z-index": "999", "min-width": "200px" },
            attrs: { branches: _vm.branchList },
            on: { change: _vm.handleBranchChange }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }