var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("multiselect", {
        staticClass: "style-chooser rounded border border-info",
        attrs: {
          options: _vm.options,
          searchable: true,
          "close-on-select": true,
          "show-no-results": true,
          "show-labels": true,
          taggable: false,
          "select-label": "Select Branch ",
          "deselect-label": "Unselect Branch",
          label: "label",
          "allow-empty": false,
          "preselect-first": _vm.preselectFirst,
          "track-by": "label",
          multiple: false,
          value: _vm.selectedBranch,
          disabled: _vm.disabled,
          placeholder: "Select Branch"
        },
        on: { input: _vm.inputChanged, "search-change": _vm.inputChanged },
        scopedSlots: _vm._u([
          {
            key: "option",
            fn: function(props) {
              return [
                !props.option.$isLabel
                  ? _c("div", [
                      _c(
                        "span",
                        {
                          staticClass: "option__title ml-2 pl-2 mr-2 float-left"
                        },
                        [
                          _c("c-avatar", {
                            attrs: {
                              initials: props.option.code,
                              username: props.option.name
                            }
                          })
                        ],
                        1
                      ),
                      _c("span", { staticClass: "option__title" }, [
                        _c("div", [
                          _c("span", [
                            _c("strong", [_vm._v(_vm._s(props.option.label))])
                          ])
                        ]),
                        _c("div", { staticClass: "font-weight-light" }, [
                          _c("small", { staticClass: "font-weight-light" }, [
                            _vm._v("UID: " + _vm._s(props.option.uid) + " ")
                          ])
                        ])
                      ])
                    ])
                  : _c("span", { staticClass: "option__title pl-2" }, [
                      _c("strong", [
                        _vm._v(" " + _vm._s(props.option.label) + " ")
                      ])
                    ])
              ]
            }
          }
        ]),
        model: {
          value: _vm.selectedBranch,
          callback: function($$v) {
            _vm.selectedBranch = $$v
          },
          expression: "selectedBranch"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }