<template>
  <CDropdown
    inNav
    class="c-header-nav-items"
    placement="bottom-end"
    add-menu-classes="pt-0"
  >
    <template #toggler>
      <CHeaderNavLink>
        <div class="c-avatar">
          <img
            :src="avatar"
            class="c-avatar-img "
          />
        </div>
      </CHeaderNavLink>
    </template>

    <CDropdownHeader
      tag="div"
      class="text-center"
      color="light"
    >
      <strong>User </strong>
    </CDropdownHeader>
    <CDropdownItem  to="/profile">
      <CIcon name="cil-user" /> Profile
    </CDropdownItem>

    <CDropdownItem>
      <CHeaderNavItem class="mx-0 px-0">

        
        <span class=" mt-0 pt-0">
          
          <!--BranchSelectorComponent v-if="$auth.role === 'admin'" >
          </!--BranchSelectorComponent-->
          <CIcon name="cil-home"
          ></CIcon>
            Branch: {{ branchName }}
          </span>

      </CHeaderNavItem>
    </CDropdownItem>

    
    
    <CDropdownItem v-if="false">
      
      <CIcon name="cil-settings" /> Settings
      
    </CDropdownItem>


    <CDropdownDivider/>
    <CDropdownItem v-if="false">
      <CIcon name="cil-shield-alt" /> Lock Account
    </CDropdownItem>
    <CDropdownItem  @click="logout()">
      <CIcon name="cil-lock-locked" /> Logout
    </CDropdownItem>
  </CDropdown>
</template>

<script>

import BranchSelectorComponent from "@/components/BranchSelectorComponent";
export default {
  name: 'TheHeaderDropdownAccnt',
  components:{
    BranchSelectorComponent
  },
  data () {
    return { 
      itemsCount: 42,
      
      // TODO: Get Avatar from user info passed from our auth server
    }
  },
  computed:{
    avatar: function(){
      return this.$auth.user.picture;
    }, 
    isAdmin(){
      return this.$auth.isAdmin();
    },
    branch() {
      let branch_id = this.$auth.branch_id;

      return this.$store.getters.selectedBranch;
      
    },
    branchName() {
      if (this.branch) {
        return this.branch.name;
      }
      return ""; 
    },
  },
  methods:{
    logout(){
      //alert("You're about to logout")
     this.$auth.logout({
        returnTo: window.location.origin // TODO: THis should not be hardcoded!!!
      }); 
    }
  }
}
</script>

<style scoped>
  .c-icon {
    margin-right: 0.3rem;
  }
</style>